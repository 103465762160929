import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const addDesignationAsync = createAsyncThunk(
  "admin/addDesignation",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addDesignation`, payload, toolkit);
  }
);
export const getDesignationByIdAsync = createAsyncThunk(
  "admin/getDesignationById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getDesignationById/${payload}`,
      [],
      toolkit
    );
  }
);
export const updateDesignationByIdAsync = createAsyncThunk(
  "admin/updateDesignationById",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateDesignationById`, payload, toolkit);
  }
);
export const getAllDesignationAsync = createAsyncThunk(
  "admin/getAllDesignation",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllDesignation?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}`,
      [],
      toolkit
    );
  }
);

// Course Status
export const getCourseStatusAsync = createAsyncThunk(
  "admin/updateCourseStatus",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateCourseStatus`, payload, toolkit);
  }
);
