import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addDepartmentAsync,
  getAllDepartmentAsync,
  getDepartmentByIdAsync,
  updateDepartmentByIdAsync,
} from "./department.async";

const initialState = {
  departmentLoader: false,
  department: [],
  departmentadd: [],
  departmentId: [],
  updateId: [],
};

export const departmentSlice = createSlice({
  name: "course",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllDepartmentAsync.pending,
        addDepartmentAsync.pending,
        getDepartmentByIdAsync.pending,
        updateDepartmentByIdAsync.pending
      ),
      (state) => {
        state.departmentLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllDepartmentAsync.fulfilled),
      (state, action) => {
        state.departmentLoader = false;
        state.department = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(addDepartmentAsync.fulfilled),
      (state, action) => {
        state.departmentLoader = false;
        state.departmentadd = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getDepartmentByIdAsync.fulfilled),
      (state, action) => {
        state.departmentLoader = false;
        state.departmentId = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(updateDepartmentByIdAsync.fulfilled),
      (state, action) => {
        state.departmentLoader = false;
        state.updateId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllDepartmentAsync.rejected,
        addDepartmentAsync.rejected,
        getDepartmentByIdAsync.rejected,
        updateDepartmentByIdAsync.rejected
      ),
      (state, action) => {
        state.departmentLoader = false;
      }
    );
  },
  reducers: {
    emptydepartment: () => initialState,
  },
});

export const { emptydepartment } = departmentSlice.actions;

export default departmentSlice.reducer;
