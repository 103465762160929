import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addDesignationAsync,
  getAllDesignationAsync,
  getDesignationByIdAsync,
  updateDesignationByIdAsync,
  getCourseStatusAsync,
} from "./designation.async";

const initialState = {
  designationLoader: false,
  designation: [],
  designationadd: [],
  designationId: [],
  updateId: [],
  getDesignantionStatus: [],
};

export const designationSlice = createSlice({
  name: "course",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllDesignationAsync.pending,
        addDesignationAsync.pending,
        getDesignationByIdAsync.pending,
        updateDesignationByIdAsync.pending,
        getCourseStatusAsync.pending
      ),
      (state) => {
        state.designationLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllDesignationAsync.fulfilled),
      (state, action) => {
        state.designationLoader = false;
        state.designation = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(addDesignationAsync.fulfilled),
      (state, action) => {
        state.designationLoader = false;
        state.designationadd = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getDesignationByIdAsync.fulfilled),
      (state, action) => {
        state.designationLoader = false;
        state.designationId = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(updateDesignationByIdAsync.fulfilled),
      (state, action) => {
        state.designationLoader = false;
        state.updateId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getCourseStatusAsync.fulfilled),
      (state, action) => {
        state.designationLoader = false;
        state.getDesignantionStatus = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllDesignationAsync.rejected,
        addDesignationAsync.rejected,
        getDesignationByIdAsync.rejected,
        updateDesignationByIdAsync.rejected,
        getCourseStatusAsync.rejected
      ),
      (state, action) => {
        state.designationLoader = false;
      }
    );
  },
  reducers: {
    emptydesignation: () => initialState,
  },
});

export const { emptydesignation } = designationSlice.actions;

export default designationSlice.reducer;
