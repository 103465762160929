import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const addDepartmentAsync = createAsyncThunk(
  "admin/addDepartment",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addDepartment`, payload, toolkit);
  }
);
export const getDepartmentByIdAsync = createAsyncThunk(
  "admin/getDepartmentById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getDepartmentById/${payload}`,
      [],
      toolkit
    );
  }
);
export const updateDepartmentByIdAsync = createAsyncThunk(
  "admin/updateDepartmentById",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateDepartmentById`, payload, toolkit);
  }
);
export const getAllDepartmentAsync = createAsyncThunk(
  "admin/getAllDepartment",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAllDepartment?page=${payload.page || ""}&limit=${
        payload.limit || ""
      }&search=${payload.search || ""}`,
      [],
      toolkit
    );
  }
);

// Course Status
export const getCourseStatusAsync = createAsyncThunk(
  "admin/updateCourseStatus",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/updateCourseStatus`, payload, toolkit);
  }
);
